import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContactBar from "../../components/ContactBar";
import SingleImage from "../../library/SingleImage";
import Layout from "../../components/Layout";

const PcoPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>PCO Syndrom</title>
        <meta
          name="description"
          content="Das PCO Syndrom wird oft im Zusammenhang mit unerfülltem Kinderwunsch gestellt"
        />
        <meta property="og:title" content="PCO Syndrom" />
        <meta
          property="og:description"
          content="Das PCO Syndrom wird oft im Zusammenhang mit unerfülltem Kinderwunsch gestellt"
        />
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>PCO Syndrom</h1>
        <SingleImage image={props.data.pcosyndrom.fluid} />
        <p>
          Das PCO Syndrom wird oft im Zusammenhang mit unerfülltem Kinderwunsch
          gestellt, da es sonst, ausser evtl. Zyklusunregelmässigkeiten, nur
          wenig Beschwerden bereit.
        </p>
        <p>
          In der TCM steht PCOS für eine Schwäche der Mitte mit Ansammlung von
          Schleim (viele Follikel). Mit Akupunktur und chinesischen Kräutern
          wird die Mitte gestärkt, Feuchtigkeit ausgeleitet und die
          Eizellreifung dahingehend unterstützt, dass die Follikel auch den
          letzten Schritt bis zum Eisprung gehen können.
        </p>
        <div className="cta">
          <p>Buchen Sie noch heute einen Termin!</p>
          <a
            className="button"
            href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?bookingOpen=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online buchen
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default PcoPage;

export const query = graphql`
  query PcoImageQuery {
    pcosyndrom: imageSharp(fluid: { originalName: { regex: "/pco/" } }) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

import React from 'react';
import Img from 'gatsby-image';


export default function SingleImage({ title, caption, image }) {
  return (
    <div className="image-container">
      <Img sizes={image} />
      {title ? <div className="title">{title}</div> : null}
      {caption ? <div className="caption">{caption}</div> : null }
    </div>
  );
}

SingleImage.propTypes = {

};
